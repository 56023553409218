<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import store from "@/state/store";
const _ = require('lodash')

export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Performance Assessment List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    //this.getOpenGoalSetting();
    this.getEmployeePerformanceDevPlan();
  },
  validations: {
    start: { required },
    end: { required },
    duration: { required },
  },
  data() {
    return {
      title: "Performance Assessment List",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Performance Assessment List",
          href: "/performance-development-plan",
          active: true,
        },
      ],


      performances: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      posted_on: null,
      audience: null,
      fields: [
        { key: "sn", label: "S/n", sortable: true },
        {
          key: "employee",
          label: "Employee",
          sortable: true,
        },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "end_date", label: "End Date", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "supervisor", label: "Supervisor", sortable: true },
        { key: "action", label: "Action", sortable: true },
      ],
    };
  },
  methods: {
    async getEmployeePerformanceDevPlan() {
      const empId = this.getEmployee.emp_id;
      const url = `${this.ROUTES.performanceImprovement}/get-all-supervisor-performance-assessments/${empId}`;
      this.apiGet(url).then((res) => {
        const { data } = res;
        data.performance.map((perf, index)=>{
          let status = 'Pending';
          let expired = false;
          switch (parseInt(perf.ppm_status)){
            case 0:
              status = 'Pending';
              break;
            case 1:
              status = 'In Review';
              break;
            case 2:
              status = 'Approved';
              break;
          }
          if(!(_.isNull(perf.ppm_end_date))){
            if(new Date(perf.ppm_end_date).getTime() < new Date().getTime() ){
              expired = true;
            }
          }
          const performance = {
            sn:index+1,
            employee: `${perf.employee.emp_first_name} ${perf?.employee.emp_last_name} (${perf?.employee.emp_unique_id})`,
            start_date: _.isNull(perf.ppm_start_date) ? '-' : new Date(perf.ppm_start_date).toDateString(),
            end_date: _.isNull(perf.ppm_end_date) ? '-' : new Date(perf.ppm_end_date).toDateString(),
            supervisor: `${perf.supervisor.emp_first_name} ${perf?.supervisor.emp_last_name} (${perf?.supervisor.emp_unique_id})`,
            status: `${ status }`,
            performanceId:perf.ppm_id,
            action:expired
          };
          this.performances.push(performance);
        })

      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectPerformanceDev(performance) {
      let performanceId = performance[0].performanceId;
      this.$router.push({
        name:"performance-assessment-detail",
        params:{
          performanceId:performanceId
        }
      });

    },
    supervisorEndOfYearPerformanceReview(performanceId){
      this.$router.push({
        name:"supervisor-end-of-year-performance-review",
        params:{
          performanceId:performanceId
        }
      });

    },
  },
};
</script>
<style>
.back {
  cursor: pointer;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <scale-loader v-if="apiBusy" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h6 class="text-uppercase">
              Performance Assessment
            </h6>
          </div>
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="donor-table"
                bordered
                selectable
                hover
                :items="performances"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                @row-selected="selectPerformanceDev"
              >
                <template #cell(status)="row">
                  <p class="mb-0">
                    <span
                      class="badge badge-primary badge-pill"
                      v-if="row.item.status === 'Approved'"
                    >
                      Approved
                    </span>

                    <span
                      class="badge badge-warning badge-pill"
                      v-if="row.item.status === 'Pending'"
                    >
                      Pending
                    </span>
                    <span
                      class="badge badge-warning badge-pill"
                      v-if="row.item.status === 'In Review'"
                    >
                      In Review
                    </span>
                  </p>
                </template>

                <template #cell(action)="row">
                  <button
                    class="btn btn-success btn-sm"
                    v-if="row.item.action"
                    @click="supervisorEndOfYearPerformanceReview(row.item.performanceId)"
                  >
                    <i class="mdi mdi-calendar"></i> Review
                  </button>
                  <button
                    class="btn btn-success btn-sm"
                    v-else
                    disabled
                  >
                    <i class="mdi mdi-eye"></i>
                    In view
                  </button>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
